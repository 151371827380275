import { gql } from "@apollo/client";

import { FEED_FOLLOW_INFO_FRAGMENT } from "graphql/feed/fragments";
import {
  FEED_GROUP_INVITE_FRAGMENT,
  FEED_GROUP_JOIN_REQUEST_FRAGMENT,
} from "graphql/groups/fragments";
import { USER_SNAPSHOT_FRAGMENT } from "graphql/user/fragments";

export const JOIN_GROUP_MUTATION = gql`
  mutation FeedGroupFollow($id: ID!) {
    feedGroupFollow(id: $id) {
      ...FeedFollowInfo_Fragment
    }
  }
  ${FEED_FOLLOW_INFO_FRAGMENT}
`;

export const LEAVE_GROUP_MUTATION = gql`
  mutation FeedGroupUnfollow($id: ID!) {
    feedGroupUnfollow(id: $id) {
      ...FeedFollowInfo_Fragment
    }
  }
  ${FEED_FOLLOW_INFO_FRAGMENT}
`;

export const GROUP_POST_PIN_MUTATION = gql`
  mutation FeedGroupPin($input: FeedGroupPinInput!) {
    feedGroupPin(input: $input)
  }
`;

export const GROUP_POST_UNPIN_MUTATION = gql`
  mutation FeedGroupUnpin($input: FeedGroupPinInput!) {
    feedGroupUnpin(input: $input)
  }
`;

export const GROUP_EP_ANSWER_MUTATION = gql`
  mutation epGroupQuestionAnswer($input: EpGroupQuestionAnswerInput!) {
    epGroupQuestionAnswer(input: $input) {
      id
      answers {
        id
        answer
        author {
          id
          name {
            fullName
          }
          snapshot {
            ...UserSnapshot_Fragment
          }
          type
        }
      }
    }
  }
  ${USER_SNAPSHOT_FRAGMENT}
`;

export const FEED_GROUP_JOIN_REQUEST = gql`
  mutation feedGroupJoinRequest($input: FeedGroupJoinRequestInput!) {
    feedGroupJoinRequest(input: $input) {
      ...FeedGroupJoinRequest_Fragment
    }
  }
  ${FEED_GROUP_JOIN_REQUEST_FRAGMENT}
`;

export const FEED_GROUP_JOIN_REQUEST_APPROVE = gql`
  mutation feedGroupJoinRequestApprove($id: ID!) {
    feedGroupJoinRequestApprove(id: $id) {
      ...FeedGroupJoinRequest_Fragment
    }
  }
  ${FEED_GROUP_JOIN_REQUEST_FRAGMENT}
`;

export const FEED_GROUP_JOIN_REQUEST_REJECT = gql`
  mutation feedGroupJoinRequestReject($id: ID!) {
    feedGroupJoinRequestReject(id: $id) {
      ...FeedGroupJoinRequest_Fragment
    }
  }
  ${FEED_GROUP_JOIN_REQUEST_FRAGMENT}
`;

export const FEED_GROUP_ACCEPT_INVITE = gql`
  mutation feedGroupInviteAccept($id: ID!) {
    feedGroupInviteAccept(id: $id) {
      id
      status
      group {
        id
        hasAccess
        openRequests {
          ... on FeedGroupInvite {
            id
            invitedAt
            status
          }
        }
      }
    }
  }
`;

export const FEED_GROUP_REJECT_INVITE = gql`
  mutation feedGroupInviteReject($id: ID!) {
    feedGroupInviteReject(id: $id) {
      id
      status
    }
  }
`;

export const FEED_GROUP_INVITE = gql`
  mutation feedGroupInvite($input: FeedGroupInviteInput!) {
    feedGroupInvite(input: $input) {
      ...FeedGroupInvite_Fragment
    }
  }
  ${FEED_GROUP_INVITE_FRAGMENT}
`;

export const FEED_GROUP_REMOVE_MEMBER = gql`
  mutation feedGroupRemoveMember($input: FeedGroupRemoveMemberInput!) {
    feedGroupRemoveMember(input: $input)
  }
`;
