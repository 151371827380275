import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { ME_QUERY } from "graphql/me/queries";

export default function useMyGroups() {
  const { data, loading } = useQuery(ME_QUERY);

  const [myPublicGroups, myPrivateGroups] = useMemo(() => {
    const defaultState = [[], []];
    if (!loading) {
      const separatedGroups = data?.me?.myGroups?.groups?.reduce((acc, cur) => {
        if (cur.deletedAt) {
          return acc;
        }
        if (cur.type === "PRIVATE") {
          acc[1].push(cur);
        } else {
          acc[0].push(cur);
        }
        return acc;
      }, defaultState);
      return separatedGroups;
    }
    return defaultState;
  }, [data, loading]);

  return {
    myGroups: data?.me?.myGroups?.groups || [],
    myPublicGroups,
    myPrivateGroups,
    groupsILead: data?.me?.myGroups?.groupsILead || [],
    loading,
  };
}
