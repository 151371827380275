import { useQuery } from "@apollo/client";
import { useCallback } from "react";

import { FEED_GROUP_FULL } from "graphql/groups/queries";
import encodeCursor from "lib/apollo/encodeCursor";
import { User } from "types/User";

export const MEMBER_PAGE_SIZE = 9;

export type Response = {
  feedGroup: {
    followers: {
      edges: {
        node: User;
        cursor: string;
      }[];
      pageInfo: {
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    };
    leadership: {
      capabilities: string[];
      role: string;
      type: string;
      user: User;
    }[];
    totalFollowers: number;
  };
};

type Props = {
  fetchPolicy?: "cache-first" | "cache-and-network" | "network-only";
  groupId: string;
  pageSize?: number;
  page?: number;
};

export default function useGroupMembers({
  fetchPolicy = "cache-first",
  groupId,
  pageSize = MEMBER_PAGE_SIZE,
  page = 1,
}: Props) {
  const offset = pageSize * (page - 1);
  const variables = {
    after: encodeCursor({ offset }),
    first: pageSize,
    input: { groupId },
  };

  const { data, error, loading, fetchMore } = useQuery<Response>(
    FEED_GROUP_FULL,
    {
      variables,
      skip: groupId.endsWith("_admin"),
      fetchPolicy,
      notifyOnNetworkStatusChange: true,
    }
  );

  const leadership =
    data?.feedGroup?.leadership?.map((leader) => ({
      ...(leader?.user?.snapshot || {}),
      type: leader.type,
      userType: leader.user.type,
      role: leader.role,
      capabilities: leader.capabilities,
    })) || [];
  const members =
    data?.feedGroup?.followers?.edges?.map((follower) => ({
      ...(follower?.node?.snapshot || {}),
      userType: follower.node.type,
    })) || [];
  const hasPreviousPage =
    data?.feedGroup?.followers?.pageInfo?.hasPreviousPage || false;
  const hasNextPage =
    data?.feedGroup?.followers?.pageInfo?.hasNextPage || false;
  const totalMembers = data?.feedGroup?.totalFollowers || 0;
  const totalPages = Math.ceil(totalMembers / pageSize) || 1;

  const fetchMoreFollowers = useCallback(() => {
    const followerEdges = data?.feedGroup?.followers?.edges;
    const after =
      followerEdges.length > 0 &&
      followerEdges[followerEdges.length - 1].cursor;

    const updateQuery = (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      const newEdges = [
        ...prev.feedGroup.followers.edges,
        ...fetchMoreResult.feedGroup.followers.edges,
      ];
      return {
        ...prev,
        feedGroup: {
          ...prev.feedGroup,
          followers: {
            ...prev.feedGroup.followers,
            edges: newEdges,
            pageInfo: fetchMoreResult.feedGroup.followers.pageInfo,
          },
        },
      };
    };

    return fetchMore({
      updateQuery,
      variables: { after },
    });
  }, [data?.feedGroup?.followers?.edges, fetchMore]);

  return {
    error,
    hasNextPage,
    hasPreviousPage,
    leadership,
    loading,
    members,
    fetchMore: fetchMoreFollowers,
    totalMembers,
    totalPages,
  };
}
