import { gql } from "@apollo/client";

import { EXPERT_PANEL_QUESTION_FRAGMENT } from "graphql/expert-panels/fragments";
import {
  FEED_GROUP_BASIC_FRAGMENT,
  FEED_GROUP_FRAGMENT,
  FEED_GROUP_FULL_FRAGMENT,
  FEED_GROUP_INVITE_FRAGMENT,
  FEED_GROUP_JOIN_REQUEST_FRAGMENT,
} from "graphql/groups/fragments";
import { USER_FRAGMENT } from "graphql/user/fragments";

export const FEED_GROUPS = gql`
  query feedGroupList($input: FeedGroupListInput!) {
    feedGroupList(input: $input) {
      ...FeedGroupBasic_Fragment
    }
  }
  ${FEED_GROUP_BASIC_FRAGMENT}
`;

export const FEED_GROUPS_MOST_ACTIVE = gql`
  query feedGroupsMostActive($input: FeedGroupsMostActiveInput!) {
    feedGroupsMostActive(input: $input) {
      ...FeedGroupBasic_Fragment
    }
  }
  ${FEED_GROUP_BASIC_FRAGMENT}
`;

export const FEED_GROUP_BASIC = gql`
  query feedGroupBasic($input: FeedGroupInput!) {
    feedGroup(input: $input) {
      ...FeedGroupBasic_Fragment
    }
  }
  ${FEED_GROUP_BASIC_FRAGMENT}
`;

export const FEED_GROUP = gql`
  query feedGroup($input: FeedGroupInput!, $first: Int) {
    feedGroup(input: $input) {
      ...FeedGroup_Fragment
    }
  }
  ${FEED_GROUP_FRAGMENT}
`;

export const FEED_GROUP_FULL = gql`
  query feedGroupFull($input: FeedGroupInput!, $first: Int, $after: String) {
    feedGroup(input: $input) {
      ...FeedGroupFull_Fragment
    }
  }
  ${FEED_GROUP_FULL_FRAGMENT}
`;

export const GROUP_EP_LIST = gql`
  query epGroupQuestionList(
    $input: EpGroupQuestionListInput!
    $first: Int
    $after: String
  ) {
    epGroupQuestionList(input: $input, first: $first, after: $after) {
      open {
        ...ExpertPanelQuestion_Fragment
        activityId
      }
      closed {
        edges {
          cursor
          node {
            ...ExpertPanelQuestion_Fragment
            activityId
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
  ${EXPERT_PANEL_QUESTION_FRAGMENT}
`;

export const FEED_GROUP_JOIN_REQUESTS = gql`
  query feedGroupJoinRequests(
    $input: FeedGroupJoinRequestsInput!
    $first: Int
    $after: String
  ) {
    feedGroupJoinRequests(input: $input, first: $first, after: $after) {
      edges {
        node {
          ...FeedGroupJoinRequest_Fragment
        }
        cursor
      }
      pageInfo {
        count
        pending
        hasNextPage
      }
    }
  }
  ${FEED_GROUP_JOIN_REQUEST_FRAGMENT}
`;

export const FEED_GROUP_INVITATIONS = gql`
  query feedGroupInvitations(
    $input: FeedGroupInvitationsInput!
    $first: Int
    $after: String
  ) {
    feedGroupInvitations(input: $input, first: $first, after: $after) {
      edges {
        node {
          ...FeedGroupInvite_Fragment
        }
        cursor
      }
      pageInfo {
        count
        pending
        hasNextPage
      }
    }
  }
  ${FEED_GROUP_INVITE_FRAGMENT}
`;

export const FEED_GROUP_NON_MEMBERS = gql`
  query feedGroupNonMembers(
    $input: FeedGroupNonMembersInput!
    $first: Int
    $after: String
  ) {
    feedGroupNonMembers(input: $input, first: $first, after: $after) {
      edges {
        node {
          ...User_Fragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${USER_FRAGMENT}
`;
