import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import {
  FEED_GROUP_JOIN_REQUEST,
  JOIN_GROUP_MUTATION,
  LEAVE_GROUP_MUTATION,
} from "graphql/groups/mutations";
import useGroup from "hooks/useGroup";

import useMe from "./useMe";

export default function useJoinGroup({ id }) {
  const { id: meId } = useMe();

  const { group } = useGroup({
    groupId: id,
  });

  const [joinGroup, { loading: isJoining }] = useMutation(JOIN_GROUP_MUTATION, {
    update(cache) {
      // Update the users myGroups
      cache.modify({
        id: cache.identify({ __typename: "Me", id: meId }),
        fields: {
          myGroups(myGroups) {
            return {
              ...myGroups,
              groups: [...myGroups.groups, { __ref: `FeedGroup:${id}` }],
            };
          },
        },
      });

      // Update the groups totalFollower count and add the user to the followers list
      cache.modify({
        id: cache.identify({ __typename: "FeedGroup", id }),
        fields: {
          totalFollowers(totalFollowers) {
            return totalFollowers + 1;
          },
          followers(followers) {
            return {
              ...followers,
              edges: [
                ...followers.edges,
                {
                  __typename: "FeedUsersEdge",
                  node: { __ref: `User:${meId}` },
                },
              ],
            };
          },
        },
      });
    },
  });

  const [leaveGroup, { loading: isLeaving }] = useMutation(
    LEAVE_GROUP_MUTATION,
    {
      update(cache) {
        // Update the users myGroups
        cache.modify({
          id: cache.identify({ __typename: "Me", id: meId }),
          fields: {
            myGroups(myGroups) {
              return {
                ...myGroups,
                groups: myGroups.groups.filter(
                  ({ __ref }) => __ref !== `FeedGroup:${id}`
                ),
              };
            },
          },
        });

        // Update the groups totalFollower count and remove the user from the followers list
        cache.modify({
          id: cache.identify({ __typename: "FeedGroup", id }),
          fields: {
            hasAccess() {
              return group?.type !== "PRIVATE";
            },
            totalFollowers(totalFollowers) {
              return totalFollowers - 1;
            },
            followers(followers) {
              return {
                ...followers,
                edges: followers.edges.filter(
                  ({ node }) => node.__ref !== `User:${meId}`
                ),
              };
            },
          },
        });
      },
    }
  );

  const [requestToJoin, { loading: isRequesting }] = useMutation(
    FEED_GROUP_JOIN_REQUEST,
    {
      update(cache, { data }) {
        cache.modify({
          id: cache.identify({ __typename: "FeedGroup", id }),
          fields: {
            openRequests() {
              return {
                ...data?.feedGroupJoinRequest,
              };
            },
          },
        });
      },
    }
  );

  const handleJoin = useCallback(async () => {
    if (group?.type === "PRIVATE") {
      await requestToJoin({ variables: { input: { groupId: id } } });
    } else {
      await joinGroup({ variables: { id } });
    }
  }, [group, requestToJoin, id, joinGroup]);

  const handleLeave = useCallback(async () => {
    await leaveGroup({ variables: { id } });
  }, [id, leaveGroup]);

  return {
    handleJoin,
    isJoining: isJoining || isRequesting,
    handleLeave,
    isLeaving,
  };
}
