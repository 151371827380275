import { useQuery } from "@apollo/client";

import { FEED_GROUPS } from "graphql/groups/queries";

export default function useGroups(props) {
  const { data, error, loading, refetch } = useQuery(FEED_GROUPS, {
    variables: {
      input: {
        filter: props?.filter || "ALL",
        sort: props?.sort || "ALPHABETICAL",
        query: props?.query || "",
      },
      first: props?.first || 25,
    },
  });

  const groups = data?.feedGroupList || [];

  return {
    error,
    groups,
    loading,
    refetch,
  };
}
