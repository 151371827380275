import { useQuery } from "@apollo/client";

import { FEED_GROUP_BASIC } from "graphql/groups/queries";

export default function useGroup({ groupId }) {
  const { data, error, loading, refetch } = useQuery(FEED_GROUP_BASIC, {
    variables: {
      input: {
        groupId,
      },
    },
    skip: !groupId,
  });

  const group = data?.feedGroup;

  return {
    error,
    group,
    loading,
    refetch,
  };
}
